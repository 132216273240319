<template>
    <div
        ref="errorContainer"
        :class="{
            'c-error-banner': true,
            'c-error-banner--white': light,
            'c-error-banner--lightgray': !light,
        }"
    >
        <div class="c-error-banner__icon-container">
            <BaseIcon icon="global--alert" size="12px" color="white" />
        </div>
        <div role="alert" class="c-error-banner__text-container u-text--gray o-text--caption">
            {{ errorText }}
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { getDistanceFromElementToTop } from '~coreModules/browser/js/browser-utils';

import { HEADER_MODULE_NAME } from '~coreModules/header/js/header-store';

export default {
    name: 'ErrorBanner',
    props: {
        errorText: {
            type: String,
            default: '',
        },
        light: {
            type: Boolean,
            default: true,
        },
        shouldScrollIn: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapState(HEADER_MODULE_NAME, {
            headerHeight: state => state.headerHeight,
        }),
    },
    mounted() {
        if (this.shouldScrollIn) {
            this.scrollIn();
        }
    },
    methods: {
        scrollIn() {
            const topOfElement = getDistanceFromElementToTop(this.$refs.errorContainer) -
                this.headerHeight - 16;
            window.scroll({ top: topOfElement, behavior: 'smooth' });
        },
    },
};
</script>

<style lang="scss">
    .c-error-banner {
        border-radius: 2px;
        display: flex;
        align-items: stretch;
        flex-wrap: nowrap;

        &--white {
            background-color: $nu-white;
        }

        &--lightgray {
            background-color: $nu-gray--light;
        }

        &__icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            background-color: $nu-primary;
            border-radius: 2px 0 0 2px;
            width: 32px;
        }

        &__text-container {
            text-align: left;
            padding: $nu-spacer-1 $nu-spacer-1pt5;
        }
    }
</style>
